import { configApiRef, createApiFactory, createPlugin, createRoutableExtension, fetchApiRef, identityApiRef } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

import { applicationInsightsPluginApiRef, ApplicationInsightsPluginClient } from './api';
import { Entity } from '@backstage/catalog-model';

// applicationId
export const APPLICATION_INSIGHTS_CLOUD_ROLE_NAME_ANNOTATION = 'bitfoundry.co/application-insights-cloud-role-name';
export const APPLICATION_INSIGHTS_APP_ID_ANNOTATION = 'bitfoundry.co/application-insights-app-id';
export const isApplicationInsightsAvailable = (entity: Entity) =>
  Boolean(entity.metadata.annotations?.[APPLICATION_INSIGHTS_APP_ID_ANNOTATION]);
  
export const applicationInsightsPlugin = createPlugin({
  id: 'application-insights-plugin',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: applicationInsightsPluginApiRef,
      deps: { configApi: configApiRef, fetchApi: fetchApiRef, identityApi: identityApiRef },
      factory: ({ configApi, fetchApi, identityApi }) =>
        new ApplicationInsightsPluginClient({ configApi, fetchApi, identityApi}),
    }),
  ],  
});

export const ApplicationInsightsPluginPage = applicationInsightsPlugin.provide(
  createRoutableExtension({
    name: 'ApplicationInsightsPluginPage',
    component: () =>
      import('./components/ApplicationInsightsComponent').then(m => m.ApplicationInsightsComponent),
    mountPoint: rootRouteRef,
  }),
);

