import { createApiRef, FetchApi, ConfigApi, IdentityApi } from '@backstage/core-plugin-api';

import { Entity, stringifyEntityRef } from '@backstage/catalog-model';

export interface ApplicationInsightsException {
  timestamp: Date,
  outerMessage: string,
  problemId: string
}

export interface ApplicationInsightsRequest {
  timestamp: Date,
  url: string,
  success: boolean,
  resultCode: number,
  duration: number,
  method: string
}

// ApplicationInsightsPluginApi.ts
export interface ApplicationInsightsPluginApi {
  getExceptions(entity: Entity): Promise<ApplicationInsightsException[]>;

  getRequests(entity: Entity): Promise<ApplicationInsightsRequest[]>;
}

// MyPluginClient.ts
export const applicationInsightsPluginApiRef = createApiRef<ApplicationInsightsPluginApi>({
  id: 'plugin.myplugin.service',
});

export class ApplicationInsightsPluginClient implements ApplicationInsightsPluginApi {
  private readonly baseUrl: string;
  private readonly fetchApi: FetchApi;
  private readonly identityApi: IdentityApi

  constructor(options: { configApi: ConfigApi; fetchApi: FetchApi, identityApi: IdentityApi }) {
    this.fetchApi = options.fetchApi;
    this.baseUrl = options.configApi.getString('backend.baseUrl');
    this.identityApi = options.identityApi;
    console.log(`${this.baseUrl}/api/application-insights-plugin/users`);
  }

  async getExceptions(entity: Entity): Promise<ApplicationInsightsException[]> {
    const { token } = await this.identityApi.getCredentials();

    let url = `${this.baseUrl}/api/application-insights/exceptions`;

    const response = await this.fetchApi.fetch(
      url,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`
          , 'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 'entityRef': stringifyEntityRef(entity) })
      },
    );

    return await response.json();
  }

  async getRequests(entity: Entity): Promise<ApplicationInsightsRequest[]> {
    const { token } = await this.identityApi.getCredentials();

    let url = `${this.baseUrl}/api/application-insights/requests`;
    const response = await this.fetchApi.fetch(
      url,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
        , body: JSON.stringify({ 'entityRef': stringifyEntityRef(entity) })
      },
    );

    return await response.json();
  }
}
